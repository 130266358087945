import {View} from '../View';
import {Text} from '../Text';
import {Icon, IconName} from '../Icon';
import {IconButton} from '../Button';

import {useStyle, useColors, ColorsType, useAppTheme, useIsRTL, useMediaQuery} from '../hooks';
import {SZ, colorNames} from '../consts';

import {ProgressBar} from '../ProgressBar';
import {Pressable} from '../Pressable';
import {TextInput, Animated, Easing, StyleProp, ViewStyle, Platform, TextStyle} from 'react-native';
import {forwardRef, useEffect, useMemo, useRef, useState} from 'react';

export type TextFieldVariant = 'focus' | 'error' | 'warning' | 'success' | 'default' | 'filled';
type TextInputProps = TextInput['props'];
export interface TextFieldProps extends TextInputProps {
  label?: string;
  disabled?: boolean;
  bottomText?: string;
  rightIcon?: IconName;
  onRightIcon?: () => void;
  rightText?: string;
  onRightText?: () => void;
  leftText?: string;
  onLeftText?: () => void;
  variant?: TextFieldVariant;
  onChangeText?: (text: string) => void;
  style?: StyleProp<ViewStyle>;
  value?: string;
  progress?: number;
  progressColor?: ColorsType;
  preventRtl?: boolean;
  onFieldPress?: () => void;
  onLabelPress?: () => void;
  inputStyle?: StyleProp<TextStyle>;
  // rtl?: boolean;
}

export const TextField = forwardRef<TextInput, TextFieldProps>((props, ref) => {
  const {
    style = {},
    variant = 'default',
    bottomText,
    label,
    onChangeText,
    onFocus,
    onBlur,
    rightIcon,
    onRightIcon,
    rightText,
    onRightText,
    leftText,
    onLeftText,
    disabled,
    value,
    progress = 0,
    progressColor = 'green',
    editable = true,
    preventRtl,
    onFieldPress,
    inputStyle = {},
    ...otherProps
  } = props;
  const colors = useColors();
  const S = useStyle();
  const [theme] = useAppTheme();
  let rtl = useIsRTL();
  rtl = preventRtl ? false : rtl;
  const {isWeb} = useMediaQuery();

  const [isFocused, setIsFocused] = useState(otherProps.autoFocus || false);

  const focusAnim = useRef(new Animated.Value(value ? 1 : 0)).current;

  useEffect(() => {
    Animated.timing(focusAnim, {
      toValue: leftText || isFocused || value ? 1 : 0,
      duration: 300,
      easing: Easing.bezier(0.4, 0, 0.2, 1),
      useNativeDriver: false,
    }).start();
  }, [focusAnim, value, isFocused, leftText]);

  const borderColor = useMemo(() => {
    if (disabled) {
      return colors.secondary_10;
    } else if (isFocused) {
      return colors.color;
    } else if (variant === 'error') {
      return colors.red;
    } else if (variant === 'warning') {
      return colors.orange;
    } else if (variant === 'success') {
      return colors.green;
    } else if (value && String(value).length) {
      return colors.secondary_50;
    }
    return colors.secondary_10;
  }, [disabled, isFocused, value, variant, colors]);

  const BottomTextColor = useMemo(() => {
    if (disabled) {
      return colorNames.secondary_50;
    } else if (variant === 'error') {
      return colorNames.red;
    } else if (variant === 'warning') {
      return colorNames.orange;
    } else if (variant === 'success') {
      return colorNames.green;
    }
    return colorNames.secondary_50;
  }, [disabled, variant]);

  return (
    <View bg="transparent" style={[S.justifyBetween, S.wFull, style]}>
      <Pressable
        onPress={() => {
          onFieldPress?.();
        }}
        dir={rtl && !preventRtl ? 'rtl' : 'ltr'}
        bg={disabled ? 'secondary_10' : 'transparent'}
        style={[
          S.row,
          {
            borderWidth: 1,
            borderColor,
            minHeight: 56,
          },
          S.align,
          S.rad2,
          S.ph1,
        ]}>
        {!!leftText && (
          <Pressable disabled={disabled} onPress={onLeftText} bg="transparent">
            <Text style={[S.ph3, S.pt4]} variant="Field">
              {leftText}
            </Text>
          </Pressable>
        )}
        <TextInput
          dir={isWeb && rtl ? 'ltr' : undefined}
          onPressIn={() => {
            onFieldPress?.();
          }}
          value={value || ''}
          editable={!disabled && editable}
          keyboardAppearance={theme}
          onChangeText={onChangeText}
          allowFontScaling={false}
          underlineColorAndroid="transparent"
          onFocus={e => {
            if (!disabled && editable) {
              onFocus?.(e);
              setIsFocused(true);
            }
          }}
          onBlur={e => {
            onBlur?.(e);
            setIsFocused(false);
          }}
          ref={ref}
          placeholderTextColor={'transparent'}
          {...otherProps}
          style={[
            S.Field,
            S.flex,
            rtl ? S.textRight : S.textLeft,
            {color: colors.color},
            {zIndex: 2},
            S.pv2,
            S.pt6,
            rtl ? S.pr3 : S.pl3,
            inputStyle,
          ]}
        />
        {!!label?.length && (
          <Animated.View
            style={[
              S.absolute,
              Platform.OS === 'web' && rtl ? {right: SZ.x4} : {left: SZ.x4},
              {
                zIndex: 1,
                overflow: 'hidden',
                width: '86%',
                top: focusAnim.interpolate({
                  inputRange: [0, 1],
                  outputRange: [20, 8],
                }),
              },
            ]}>
            <Animated.Text
              numberOfLines={1}
              style={[
                S.Label,
                {
                  fontSize: focusAnim.interpolate({
                    inputRange: [0, 1],
                    outputRange: [16, 12],
                  }),
                  color: disabled ? colors.secondary_25 : colors.secondary_50,
                },
                Platform.OS !== 'web' && S.textLeft,
                rtl && {lineHeight: undefined},
              ]}>
              {label}
            </Animated.Text>
          </Animated.View>
        )}
        {!!rightIcon && (
          <IconButton
            disabled={disabled}
            name={rightIcon}
            color={disabled ? 'secondary_25' : 'secondary_50'}
            onPress={onRightIcon}
          />
        )}
        {!!rightText && (
          <Pressable disabled={disabled} onPress={onRightText} bg="transparent" style={S.ph3}>
            <Text color={disabled ? 'secondary_25' : 'secondary_50'} variant="Field">
              {rightText}
            </Text>
          </Pressable>
        )}
      </Pressable>
      {!!progress && <ProgressBar style={S.mt2} progress={progress} color={progressColor} />}
      {bottomText && (
        <View bg="transparent" style={[S.pt2, S.row, S.gap2, S.align]}>
          <Icon name="Info" size={16} color={BottomTextColor} />
          <Text style={S.flex} variant="Notification" color={BottomTextColor}>
            {bottomText}
          </Text>
        </View>
      )}
    </View>
  );
});
